import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import templateStore from "./templateStore";
import profileStore from "./profileStore";
import authStore from "./authStore";
import { Promise } from "bluebird";
import { ProfileType } from "../types/enum";
import ValidationHelper from '../helper/validation';

import Geocode from "react-geocode";
import Profile from "../Profile";

Geocode.setApiKey("AIzaSyC_vDgrbMpY_i3-bCcrml4kbZA56yQveRs");

const BASE_URL = process.env.REACT_APP_BASE_URL;

class EditProfileStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable id = undefined;
    @observable userId = 0;
    @observable actNameTitle = "Create Act";
    @observable venueNameTitle = "Create Venue";

    @observable isNew = true;

    @observable actName = "";
    @observable lineUpCount = 0;
    @observable profileName = "";
    @observable firstName = "";
    @observable lastName = "";
    @observable email = "";
    @observable street = "";
    @observable city = "";
    @observable postal = "";
    @observable state = "";
    @observable instruments = "";
    @observable genres = "";
    @observable gigTypes = "";
    @observable vibes = "";
    @observable bio = "<p></p>";
    @observable festivalDescription = "<p></p>";
    @observable festivalTitle = "";
    @observable contactName = "";
    @observable contactPhone = "";
    @observable type = 0; // 1 == musician
    @observable regionId = undefined;
    @observable actTypeId = 0;

    @observable gearList = "<p></p>";
    @observable countryCode = "";


    @observable venueWebsite = "";
    @observable venuePhone = "";
    @observable venueName = "";
    @observable venuePhoto = "";
    
    @observable profileProductionType = 0;

    @observable longitude = undefined;
    @observable latitude = undefined;

    @observable searchedAddress = "";

    @observable profileVenueTypes = undefined;

    @observable festivalCode = undefined;
    @observable festivalRegistrationUrl = undefined;

    @observable isConsultant = false;

    @observable isCreateConsultant = false;
    @observable actAdminId = null;
    @observable placeId = ""
    @observable tradingHours = []

    @action
    setProfileVenueType(venueTypes) {
        this.profileVenueTypes = venueTypes;
    }

    @action
    setPlaceId(value) {
        this.placeId = value;
    }

    @action
    setCreateNewConsultant(state){
        this.isCreateConsultant = state
    }

    @action
    setSearchedAddress(value) {
        this.searchedAddress = value;
    }

    @action
    setLongitudeLatitude = value => {
        this.longitude = value.lng;
        this.latitude = value.lat;
    };

    @action
    setActAdminId = value => {
        this.actAdminId = value
    };

    @action
    setfestivalCode(value) {
        this.festivalCode = value;
        this.festivalRegistrationUrl = encodeURI(`${BASE_URL}register?source=${this.festivalCode}`)
    }

    @action
    getAddress (lat, long) {
        if(lat && long) {
            return Geocode.fromLatLng(lat, long).then(
                action(response => {
                    if(response.results && response.results.length > 0){
                        const {formatted_address} = response.results[0]
                        return formatted_address
                    } else return ''
                })
            )
        } else return ''
    };

    @action
    loadInitialData(profileId) {
        const { currentUser } = userStore;
        const { currentProfile } = profileStore;      
        if(profileId){
            this.id = profileId
            profileStore.setStatusCreateVenue(false)
        }
        this.errors = undefined;
        if (currentUser) {
            this.inProgress = true;
            // templateStore.showLoadingScreen();
            if ((this.type === ProfileType.Staff.ordinal || this.type === ProfileType.Act.ordinal || this.type === ProfileType.Venue.ordinal || this.type === ProfileType.Agent.ordinal) && profileId) {
                return profileStore
                    .getProfile(profileId)
                    .then(
                        action(profile => {
                            if (!profile) {
                                this.reset();
                                throw new Error("Can't load profile");
                            }
                            this.actName = profile.actName || "";
                            this.profileName = profile.profileName || "";
                            this.firstName = profile.firstName || currentUser.firstName || currentUser.given_name;
                            this.lastName = profile.lastName || currentUser.lastName || currentUser.family_name;
                            this.email = profile.email || currentUser.email;
                            this.street = profile.street || "";
                            this.city = profile.city || "";
                            this.postal = profile.postal || "";
                            this.state = profile.state || "";
                            this.festivalTitle = profile.festivalTitle || "";
                            // this.instruments = profile.profileInstruments;
                            this.genres = profile.profileGenres;
                            this.gigTypes = profile.profileGigTypes;
                            this.vibes = profile.profileVibes;
                            this.bio = profile.bio || "<p></p>";
                            this.festivalDescription = profile.festivalDescription || "<p></p>";
                            this.type = profile.type;
                            this.id = profile.id;
                            this.actNameTitle = "Edit Act - " + this.actName;
                            this.venueNameTitle = "Edit Venue - " + this.profileName;

                            this.isNew = false;

                            this.contactName = profile.contactName || "";
                            this.contactPhone = profile.contactPhone || "";
                            this.lineUpCount = profile.lineUpCount;
                            this.regionId = profile.regionId;
                            this.actTypeId = profile.actTypeId;
                            this.countryCode = profile.countryCode;

                            this.longitude = profile.longitude;
                            this.latitude = profile.latitude;

                            this.showEmail = profile.showEmail;
                            this.showContactPhone = profile.showContactPhone;
                            this.profileVenueTypes = profile.profileVenueTypes

                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }

                            if (profile.profileGigTypes) {
                                this.gigTypes = profile.profileGigTypes.map(gigType => gigType.gigTypeId).join();
                            }

                            if (profile.profileGenres) {
                                this.genres = profile.profileGenres.map(genre => genre.genreId).join();
                            }

                            if (profile.profileVibes) {
                                this.vibes = profile.profileVibes.map(vibe => vibe.vibeId).join();
                            }

                            if (profile.profileVenueTypes) {
                                this.profileVenueTypes = profile.profileVenueTypes.map(profileVenueType => profileVenueType.venueTypeId).join();
                            }

                            this.searchedAddress = `${this.street}, ${this.city}, ${this.postal}, ${this.state}, ${this.countryCode}`
                            // if(this.latitude && this.longitude) {
                            //     this.getAddress(this.latitude, this.longitude)
                            //         .then(action(res => this.searchedAddress = res))
                            // }
                            return profile;
                        })
                    )
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            } else if (this.type !== ProfileType.Act.ordinal && this.type !== ProfileType.Venue.ordinal) {
                return profileStore
                    .loadProfile(currentUser.sub, this.type, true)
                    .then(
                        action(profile => {
                            if (!profile) {
                                this.reset();
                                throw new Error("Can't load profile");
                            }
                            this.actName = profile.actName || "";
                            this.profileName = profile.profileName || "";
                            this.firstName = profile.firstName || currentUser.firstName;
                            this.lastName = profile.lastName || currentUser.lastName;
                            this.email = profile.email || currentUser.email;
                            this.street = profile.street || "";
                            this.city = profile.city || "";
                            this.postal = profile.postal || "";
                            this.state = profile.state || "";
                            this.instruments = profile.profileInstruments;
                            this.genres = profile.profileGenres;
                            this.gigTypes = profile.profileGigTypes;
                            this.vibes = profile.profileVibes;
                            this.bio = profile.bio || "<p></p>";
                            this.festivalDescription = profile.festivalDescription || "<p></p>";
                            this.gearList = profile.gearList || "<p></p>";
                            this.type = profile.type;
                            this.contactName = profile.contactName || "";
                            this.contactPhone = profile.contactPhone || "";
                            this.festivalTitle = profile.festivalTitle || "";
                            this.id = profile.id;
                            this.actTypeId = profile.actTypeId;
                            this.actNameTitle = "Edit Act -" + this.actName;
                            this.venueNameTitle = "Edit Venue -" + this.profileName;
                            this.isNew = false;
                            this.lineUpCount = 0;

                            this.longitude = profile.longitude;
                            this.latitude = profile.latitude;

                            this.showEmail = profile.showEmail;
                            this.showContactPhone = profile.showContactPhone;

                            this.countryCode = profile.countryCode;

                            // if (this.latitude && this.longitude) {
                            // this.getAddress(this.latitude, this.longitude)
                            //     .then(action(res => this.searchedAddress = res))
                            // }
                            this.searchedAddress = `${this.street}, ${this.city}, ${this.postal}, ${this.state}, ${this.countryCode}`

                            if (profile.profileGenres) {
                                this.genres = profile.profileGenres.map(genre => genre.genreId).join();
                            }

                            if (profile.profileVibes) {
                                this.vibes = profile.profileVibes.map(vibe => vibe.vibeId).join();
                            }

                            if (profile.profileGigTypes) {
                                this.gigTypes = profile.profileGigTypes.map(gigType => gigType.gigTypeId).join();
                            }

                            if (profile.profileInstruments) {
                                this.instruments = profile.profileInstruments.map(instrument => instrument.instrumentId).join();
                            }

                            if (!profileStore.isCreateVenue) {
                                profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                            }

                            this.festivalCode = profile.festivalCode;

                            this.festivalRegistrationUrl = encodeURI(`${BASE_URL}register?source=${this.festivalCode}`);
                        })
                    )
                    .catch(action(err => {
                        this.firstName = currentUser.given_name;
                        this.lastName = currentUser.family_name;
                    }))
                    .finally(
                        action(() => {
                            this.inProgress = false;
                            templateStore.hideLoadingScreen();
                        })
                    );
            } else if(this.type === ProfileType.Act.ordinal && !profileId && currentProfile?.type === ProfileType.Musician.ordinal){ // for create new Act
                return profileStore
                .loadProfile(currentUser.sub, ProfileType.Musician.ordinal, true)
                .then(
                    action(profile => {
                        if (!profile) {
                            this.reset();
                            throw new Error("Can't load profile");
                        }
                        this.contactPhone = profile.contactPhone || "";
                        this.showEmail = profile.showEmail;
                        this.showContactPhone = profile.showContactPhone;
                        this.street = profile.street || "";
                        this.city = profile.city || "";
                        this.postal = profile.postal || "";
                        this.state = profile.state || "";
                        this.countryCode = profile.countryCode;
                        this.longitude = profile.longitude;
                        this.latitude = profile.latitude;
                        this.genres = profile.profileGenres;
                        this.bio = profile.bio || "<p></p>";

                        // if(this.latitude && this.longitude) {
                        //     this.getAddress(this.latitude, this.longitude)
                        //         .then(action(res => this.searchedAddress = res))
                        // }
                        this.searchedAddress = `${this.street}, ${this.city}, ${this.postal}, ${this.state}, ${this.countryCode}`

                        if (profile.profileGenres) {
                            this.genres = profile.profileGenres.map(genre => genre.genreId).join();
                        }
                    })
                )
                .catch(action(err => {
                    this.firstName = currentUser.given_name;
                    this.lastName = currentUser.family_name;
                }))
                .finally(
                    action(() => {
                        this.inProgress = false;
                        templateStore.hideLoadingScreen();
                    })
                );
            }
            else {
                this.inProgress = false;
                templateStore.hideLoadingScreen();
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    }

    @action
    reset() {
        const { currentUser } = userStore;

        this.actName = "";
        this.lineUpCount = 0;
        this.profileName = "";
        this.firstName = currentUser.firstName;
        this.lastName = currentUser.lastName;
        this.email = currentUser.email;
        this.street = "";
        this.city = "";
        this.postal = "";
        this.state = "";
        this.instruments = "";
        this.genres = "";
        this.vibes = "";
        this.gigTypes = "";
        this.bio = "<p></p>";
        this.festivalDescription = "<p></p>";
        this.contactName = "";
        this.festivalTitle = "";
        this.contactPhone = "";
        this.venueWebsite = "";
        this.venuePhone = "";
        this.venueName = "";
        this.venuePhoto = "";
        this.actNameTitle = "Create Act";
        this.venueNameTitle = "Create Venue";
        this.id = undefined;
        this.regionId = undefined;
        this.actTypeId = 0;
        this.countryCode = "";
        this.searchedAddress = "";
        this.longitude = undefined;
        this.latitude = undefined;
        this.showEmail = false;
        this.showContactPhone = false;
        this.isNew = true;
        this.festivalCode = "";
        this.festivalRegistrationUrl = "";
    }

    @action
    resetErrors() {
        this.errors = {
            email: "",
            firstName: "",
            lastName: "",
            actName: ""
        }
    }

    @action
    setId(id) {
        this.id = id;
    }

    @action
    setActName(actName) {
        this.actName = actName;
        this.actNameTitle = "Create Act - " + actName;
        if(this.errors === 'undefined' || this.errors === undefined) this.resetErrors()
        if(this.actName.length === 0) {
            this.errors.actName = "This field is required"
        } else {
            this.errors.actName = ""
        }
    }

    @action
    setLineUpCount(lineUpCount) {
        this.lineUpCount = lineUpCount;
    }

    @action
    increaseLineUpCount() {
        this.lineUpCount++;
    }

    @action
    setProfileName(profileName) {
        this.profileName = profileName;
    }

    @action
    setFirstName(firstName) {
        this.firstName = firstName;
        if(this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        if(this.firstName.length === 0) {
            this.errors.firstName = "This field is required"
        } else {
            this.errors.firstName = ""
        }
    }

    @action
    setLastName(lastName) {
        this.lastName = lastName;
        if(this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        if(this.lastName.length === 0) {
            this.errors.lastName = "This field is required"
        } else {
            this.errors.lastName = ""
        }
    }

    @action
    setEmail(email) {
        this.email = email;
        if(this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors["email"] = "";
    }

    @action
    validateEmail(email) {
        var email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        /* eslint-enable no-useless-escape*/
        if(this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        if (!email_regex.test(email)) {
            this.errors.email = "Invalid Email"
        } else {
            this.errors["email"] = "";
        }
    }

    @action
    setStreet(street) {
        this.street = street;
        this.getLongitudeLatitude();
    }

    @action
    setCity(city) {
        this.city = city;
        this.getLongitudeLatitude();
    }

    @action
    setPostal(postal) {
        this.postal = postal;
        this.getLongitudeLatitude();
    }

    @action
    setState(state) {
        this.state = state;
        this.getLongitudeLatitude();
    }
    @action
    setWebsiteChange(value) {
        this.venueWebsite = value;
    }
    @action
    setPhoneChange(value) {
        this.venuePhone = value;
    }
    @action
    setVenueNameChange(value) {
        this.venueName = value;
    }
    @action
    setPhotoChange(value) {
        this.venuePhoto = value;
    }

    @action
    setTradingHours(value) {
        this.tradingHours = value
    }
    @action
    setCountryCode(countryCode) {
        this.countryCode = countryCode;
        this.getLongitudeLatitude();
    }

    @action
    setInstruments(instruments) {
        this.instruments = instruments;
    }

    @action
    setGenres(genres) {
        this.genres = genres;
    }

    @action
    setGigTypes(gigTypes) {
        this.gigTypes = gigTypes;
    }

    @action
    setVibes(vibes) {
        this.vibes = vibes;
    }

    @action
    setBio(bio) {
        this.bio = bio || "<p></p>";
    }

    @action
    setFestivalDescription(festivalDescription) {
        this.festivalDescription = festivalDescription || "<p></p>";
    }

    @action
    setFestivalTitle(festivalTitle) {
        this.festivalTitle = festivalTitle;
    }

    @action
    setGearList(gear) {
        this.gearList = gear || "<p></p>";
    }

    @action
    setType(type) {
        this.type = type;
    }

    @action
    setIsConsultant(isConsultant) {
        this.isConsultant = isConsultant;
    }

    @action
    setContactName(contactName) {
        this.contactName = contactName;
    }

    @action
    setContactPhone(contactPhone) {
        this.contactPhone = contactPhone;
    }

    @action
    setRegionId(regionId) {
        this.regionId = regionId;
    }

    @action
    setActTypeId(actTypeId) {
        this.actTypeId = actTypeId;
    }

    // @action
    // setCountryCode(countryCode) {
    //     this.countryCode = countryCode;
    // }

    @action
    setProductionType(profileProductionTypeCode) {
        this.profileProductionType = profileProductionTypeCode;
    }

    @action
    getLongitudeLatitude() {
        let address = `${this.street}, ${this.city} ${this.state}, ${this.postal}, ${this.countryCode}`;
        return Geocode.fromAddress(address).then(
            action(response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.longitude = lng;
                this.latitude = lat;
                return response;
            }),
            error => {
                return Promise.resolve();
            }
        );
    }
    @action
    emailValidation(){
        if(this.errors === 'undefined' || this.errors === undefined) this.resetErrors()
        if (!ValidationHelper.emailValidation(this.email)) {
            if (this.errors){
                this.errors.email = "Please use valid email address";
            }
            else {
                this.errors = {
                    email : "Please use valid email address"
                }
            }
        }
    }
    @action
    createActValidation() {
        if(this.errors === 'undefined' || this.errors === undefined) this.resetErrors()
        if(!this.actName && this.type === ProfileType.Act.ordinal){
            this.errors.actName = "This field is required"
        } else if (!this.lineUpCount && this.type === ProfileType.Act.ordinal) {
            this.errors.lineUpCount = "This field is required"
        } else if (!this.actTypeId && this.type === ProfileType.Act.ordinal) {
            this.errors.actTypeId = "This field is required"
        } else {
            this.errors.actName = "";
            this.errors.lineUpCount = "";
            this.errors.actTypeId = "";
        }
    }

    @action
    countryValidation(){
        if(!this.countryCode){
            if (this.errors){
                this.errors.country = "This field is required";
            }
            else {
                this.errors = {
                    country : "This field is required"
                }
            }
        }
    }


    @action
    postalValidation(){
        if(!this.postal){
            if (this.errors){
                this.errors.postal = "This field is required";
            }
            else {
                this.errors = {
                    postal : "This field is required"
                }
            }
        }
    }

    @action
    firstAndLastNameValidation() {
        if(!this.firstName || !this.lastName || this.firstName === 'undefined' || this.firstName === undefined || this.lastName === 'undefined' || this.lastName === undefined) {
            if(!this.firstName || this.firstName === 'undefined' || this.firstName === undefined) {
                if (this.errors){
                    this.errors.firstName = "This field is required";
                }
                else {
                    this.errors = {
                        firstName : "This field is required"
                    }
                }
            }
            if(!this.lastName || this.lastName === 'undefined' || this.lastName === undefined) {
                if (this.errors){
                    this.errors.lastName = "This field is required";
                }
                else {
                    this.errors = {
                        lastName : "This field is required"
                    }
                }
            }
            return false
        }
        return true
    }
    @action
    submit() {
        this.inProgress = true;
      
        this.errors = undefined;
        this.countryValidation()
        this.postalValidation()
        if(this.errors){
            this.inProgress = false;
            return Promise.reject()
        }
        const user = userStore.currentUser;
        
        let vibesArray = this.vibes === "" || this.vibes.length === 0 ? [] : this.vibes.split(",").map(vibeId => ({ id: vibeId }));
        let genresArray = this.genres === "" || this.genres.length === 0 ? [] : this.genres.split(",").map(genreId => ({ id: genreId }));
        let instrumentsArray =
            this.instruments === "" || this.instruments.length === 0 ? [] : this.instruments.split(",").map(instrumentId => ({ id: instrumentId }));
        let gigTypesArray = this.gigTypes === "" || this.gigTypes.length === 0 ? [] : this.gigTypes.split(",").map(gigTypeId => ({ id: gigTypeId }));
        let venueTypeArray = this.profileVenueTypes ? this.profileVenueTypes.split(",").map(venueTypeId => ({ id: parseInt(venueTypeId, 10) })) : [];

        var profile = {
            id: this.id,
            userId: user.sub,
            actName: this.actName,
            profileName: this.profileName,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            street: this.street,
            city: this.city,
            postal: this.postal,
            state: this.state,
            instruments: instrumentsArray,
            vibes: vibesArray,
            genres: genresArray,
            gigTypes: gigTypesArray,
            bio: this.bio,
            festivalDescription: this.festivalDescription,
            festivalTitle: this.festivalTitle,
            contactName: this.contactName,
            contactPhone: this.contactPhone,
            type: this.type,
            lineUpCount: this.lineUpCount,
            regionId: this.regionId,
            actTypeId: this.actTypeId,
            countryCode: this.countryCode,
            profileProductionType: this.profileProductionType,
            gearList: this.gearList,
            longitude: this.longitude,
            latitude: this.latitude,
            venueTypes: venueTypeArray,
            festivalCode: this.festivalCode, 
            showEmail: this.showEmail, 
            showContactPhone: this.showContactPhone,
            isConsultant : this.isConsultant || profileStore.profile?.isConsultant,
            actAdminId: this.actAdminId ? this.actAdminId : profileStore?.profile?.id ? profileStore.profile.id : undefined
        };
        if(profile.type === ProfileType.Staff.ordinal){
            profile.isFreeUser = true
        }

        // for create NEW consultant profile when musician is invited by agent, but not have agent profile on their account
        if (this.isCreateConsultant) {
            profile.id = null
            this.id = null
        }


        return (this.id ? this.updateProfile(profile) : this.createProfile(profile))
            .then(
                action(profile => {
                    this.id = profile.id
                    
                        var newProfile = {}

                        if(profile.type == ProfileType.Agent.ordinal){
                            
                            if (profile.isConsultant) {                            
                                newProfile = {
                                    ...profileStore.currentProfile,
                                    firstName: profile.firstName,
                                    lastName: profile.lastName,
                                    isConsultant : true,
                                    type : ProfileType.Agent.ordinal,
                                    labelType : "Consultant",
                                    id : profile.id,
                                    profileImage : profileStore.currentProfile.profileImage ? profileStore.currentProfile.profileImage : null
                                };
                                profileStore.setCurrentProfile(newProfile)
                                this.isCreateConsultant = false

                            }else{
                               // agent 
                                newProfile = {
                                    ...window.location.pathname.includes("/new")?profile:profileStore.currentProfile,
                                    firstName: profile.firstName,
                                    lastName: profile.lastName,
                                    isConsultant : false,
                                    type : ProfileType.Agent.ordinal,
                                    profileName: profile.profileName,
                                    labelType : "Agent",
                                    id : profile.id,
                                    profileImage : window.location.pathname.includes("/new")?null: profileStore.currentProfile.profileImage
                                };
                                profileStore.setCurrentProfile(newProfile)
                            }
                    
                    } else {

                        if(profile.type!==ProfileType.Venue.ordinal && profile.type!==ProfileType.Act.ordinal){
                            newProfile = {
                                ...window.location.pathname.includes("/new")?profile:profileStore.currentProfile,
                                firstName: profile.firstName,
                                lastName: profile.lastName,
                                labelType : templateStore.getLabelType(profile.type),
                                id : profile.id,
                                profileImage : window.location.pathname.includes("/new")?null: profileStore.currentProfile.profileImage
                            };
                            profileStore.setCurrentProfile(newProfile)
                        }
                    }
                    
                    const currentProfile = profileStore.currentProfile;
                    
                    // if we are editing the current profile, update the data so the name in the sidebar and header get updated
                    if (currentProfile && currentProfile.id === profile.id) {
                        profileStore.updateCurrentProfileName(profile);
                    }

                    // in some cases like when testing songlist we need to refresh the profiles before being able to access the song list
                    if (!currentProfile) {
                        profileStore.loadProfiles();
                    }
                    
                    // if(profile.type == ProfileType.Agent.ordinal || profile.type == ProfileType.Staff.ordinal || profile.type == ProfileType.Musician.ordinal){
                    //     profileStore.setCurrentNames({...profile, fullName: `${profile.firstName} ${profile.lastName}`});
                    // }

                    return profile;
                })
            )
            .catch(
                action(err => {
                    this.errors = err.response && err.response.data.errors;
                    throw err;
                })
            )
            .finally(
                action(res => {
                    // profileStore.loadProfiles(); //refresh profiles after editing general section in case of name change
                    this.inProgress = false;
                    return res;
                })
            );
    }

    @action
    createProfile(data) {
        //using general profile for create
        switch (this.type) {
            
            case ProfileType.Musician.ordinal:
                return agent.MusicianGeneralProfile.create(data).then(profile => {
                    return authStore.refreshTokenManually().then(() => {
                        this.id = profile.id;
                        return profile;
                    });
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentGeneralProfile.create(data).then(profile => {
                    return authStore.refreshTokenManually().then(() => {
                        this.id = profile.id;
                        return profile;
                    });
                });
            case ProfileType.Staff.ordinal:
                return agent.StaffGeneralProfile.create(data).then(profile => {
                    return authStore.refreshTokenManually().then(() => {
                        this.id = profile.id;
                        return profile;
                    });
                });
            case ProfileType.Act.ordinal:
                return agent.ActGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenueGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Production.ordinal:
                return agent.ProductionGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Dj.ordinal:
                return agent.DjGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.PrivateHire.ordinal:
                return agent.PrivateHireGeneralProfile.create(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            default:
                break;
        }
    }

    @action
    updateProfile(data) {
        switch (this.type) {
            case ProfileType.Musician.ordinal:
                return agent.MusicianGeneralProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Agent.ordinal:
                return agent.AgentGeneralProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Staff.ordinal:
                return agent.StaffGeneralProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Act.ordinal:
                return agent.ActGeneralProfile.update(data).then(profile => {
                    this.setId(profile.id);
                    return profile;
                });
            case ProfileType.Venue.ordinal:
                return agent.VenueGeneralProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Production.ordinal:
                return agent.ProductionGeneralProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.Dj.ordinal:
                return agent.DjGeneralProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            case ProfileType.PrivateHire.ordinal:
                return agent.PrivateHireGeneralProfile.update(data).then(profile => {
                    this.id = profile.id;
                    return profile;
                });
            default:
                break;
        }
    }

    // Region show hide email and contact phone
    @observable showEmail = false;
    @observable showContactPhone = false;

    @action
    setShowEmail(showEmail) {
        this.showEmail = showEmail;
    }

    @action
    setShowContactPhone(showContactPhone) {
        this.showContactPhone = showContactPhone;
    }
}

export default new EditProfileStore();
