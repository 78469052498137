import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Button, Paper, Tab, Tabs } from "@material-ui/core";

import ResponsiveTable from "../table/ResponsiveTable";
import ActInvitationModal from "../util/ActInvitation/ActInvitationModal";
import { Promise } from "bluebird";
import { ProfileRelationType, ProfileType } from "../../types/enum";
import ConfirmationModal from "../modal/ConfirmationModal";
import TableRowProfile from "../util/TableRowProfile";

import ActOwnerInvitationModal from "../util/ActOwnerInvitation/ActOwnerInvitationModal";
import Loading from "../loadingSpinner/Loading";
import { runInAction } from "mobx";
import image from "../../helper/image";
import blankProfile from "../../img/hotgig-loading.png"

@inject("agentActStore", "templateStore", "userInvitationStore", "profileStore")
@observer
class AgentActRoster extends Component {
    state = {
        actInvitationModalState: false,
        deleteActModalState: false,
        deletedAct: undefined,
        deleteRequestModalState: false,
        actOwnerInvitationModalState: false,
        deleteInvitationModalState: false,
        declineInvitationState: false,
        tabValue: 0,
    };

    columns = [
        { key: "actName", label: "Act Name",width: "15%" , sortable: true, isString: true,addComp: true},
        { key: "actOwner", label: "Act Owner", width: "15%",sortable: true, isString: true, addComp: true  },
        { key: "actTypeName", label: "Act Type", width: "10%",sortable: true, isString: true  },
        { key: "lineupCountName", label: "Lineup Count", width: "10%",sortable: true, isString: true  },
        { key: "vibesName", label: "Vibes", width: "20%",sortable: true, isString: true  },
        { key: "actContact", label: "Act Contact", width: "10%",sortable: true, isString: true  },
        { key: "status", label: "Status", width: "10%",sortable: true },
        { key: "action", label: "", width: "10%" }
    ];
    pendingColumns = [
        { key: "actName", label: "Act Name",sortable: true, isString: true ,  width: "20%" },
        { key: "actOwner", label: "Act Owner", width: "20%" },
        { key: "status", label: "Status", width: "10%" },
        { key: "action", label: "", width: "30%" }
    ];

    rows = [];
    pendingRows = [];

    componentDidMount() {
        // this.props.templateStore.showLoadingScreen();
    }

    mapInviteRequests = () => {
        this.pendingRows = [];

        this.props.userInvitationStore.invitations.forEach(data => {
            var row = {};

            row.actOwner = <TableRowProfile name={`${data.invitedFirstName} ${data.invitedLastName}`} avatarUrl={data.ownerImage ? data.ownerImage : blankProfile} profileId={data.invitedVenue}/>;

            row.actName = <TableRowProfile name={data.invitedAct} avatarUrl={data.actImage ? data.actImage : blankProfile} profileId={data.additionalData}/>;

            row.status = data.additionalData ? "Pending" : "invited";

            row.action = (
                <Fragment>
                    {data.additionalData ? 
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.handleDeclineInvitation(data.id, data.invitedAct)}>
                                Decline
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.handleAcceptInvitation(data)}>
                                Accept
                            </Button>
                    </Fragment> :
                    <Button size="small" color="primary" onClick={() => this.handleOpenDeleteInvitationModal(data)}>
                        Remove
                    </Button>
                } 
                </Fragment>
            );

            row.style = {
                backgroundColor: "#EEE"
            };

            this.pendingRows.push(row);
        });
        return Promise.resolve();
    };

    mapPendingRequest = () => {
        this.props.agentActStore.pendingAgentActs.forEach(data => {
            var act = data.primaryProfile.type === ProfileType.Act.ordinal ? data.primaryProfile : data.secondaryProfile;
            var sentByAct = data.primaryProfile.type === ProfileType.Act.ordinal;

            var row = {};

            row.actName = <TableRowProfile profileId={act.myMusician.id} name={act.fullName} />;

            row.actOwner = <TableRowProfile profileId={act.myMusician.id} name={act.myMusician.fullName} />;

            row.actContact = act.contactName + " - " + act.contactPhone;

            row.status = "Pending";

            row.action = sentByAct ? (
                <Fragment>
                    <Button size="small" onClick={() => this.handleDeclineRequest(data)}>
                        Decline
                    </Button>
                    <Button size="small" color="primary" onClick={() => this.handleAcceptRequest(data)}>
                        Accept
                    </Button>
                </Fragment>
            ) : (
                <Button size="small" color="primary" onClick={() => this.handleOpenDeleteRequestModal(data)}>
                    Remove
                </Button>
            );

            row.style = {
                backgroundColor: "#EEE"
            };

            this.pendingRows.push(row);
        });
    };

    mapAccepted = () => {
        this.rows = [];
        this.props.agentActStore.agentActs.forEach(data => {
            var act = data.act;
            var row = {};
            if (!act) {
                return row;
            }

            row.actName =  {newFormat: 
                (
                    <TableRowProfile profileId={act.id} name={act.actName} avatarUrl={image.getProfileImage(act)} />

                ), value: act.actName};
            row.actOwner = {newFormat: 
            (
                <TableRowProfile profileId={act.myMusician?.id} name={act.myMusician?.fullName} avatarUrl={image.getProfileImage(act.myMusician)} />

            ), value: act.myMusician?.fullName};
            row.actContact = act.contactPhone ? act.contactName + " - " + act.contactPhone : "-";
           
            row.actTypeName = act.actTypeName ? act.actTypeName : "-";
            row.lineupCountName = act.lineupCountName ? act.lineupCountName : "-";
            row.vibesName = act.vibesName ? act.vibesName : "-";
           
            row.status = "Accepted";

            row.action = (
                <Fragment>
                    {data.id ? 
                        <Button size="small" color="primary" onClick={() => this.handleOpenDeleteModal(data)}>
                            Remove
                        </Button> : null
                    }
                </Fragment>
            );
            if (this.props.agentActStore.agentActs.filter(x => x.act.id == act.id).length == 1 || !data.id) {
                this.rows.push(row);
            }
        });
    };

    openActInvitationModal = () => {
        this.props.agentActStore.openActInvitationModal();
    };

    closeActInvitationModal = () => {
        this.props.agentActStore.closeActInvitationModal();
    };

    openActOwnerInvitationModal = value => {
        this.props.userInvitationStore.resetInvitedData();
        this.setState(
            {
                ...this.state,
                actOwnerInvitationModalState: true,
                actInvitationModalState: false
            },
            () => {
                this.props.userInvitationStore.setInvitedActName(value);
                this.closeActInvitationModal()
            }
        );
    };

    closeActOwnerInvitationModal = () => this.setState({ ...this.state, actOwnerInvitationModalState: false });

    handleDeleteAct = () => {
        this.props.agentActStore.removeAgentAct(this.state.deletedAct.id).then(() => {
            this.props.templateStore.openSnackbar(this.state.deletedAct.act.fullName + " has been removed.");
            this.handleCloseDeleteModal();
        });
    };

    handleDeleteRequest = () => {
        this.props.agentActStore.removeRequest(this.state.deletedAct.id).then(() => {
            this.props.templateStore.openSnackbar(this.state.deletedAct.secondaryProfile.fullName + " has been removed.");
            this.handleCloseDeleteModal();
        });
    };

    handleDeleteInvitation = () => {
        this.props.userInvitationStore.deleteInvitation(this.state.deletedAct).then(() => {
            this.props.templateStore.openSnackbar(this.state.deletedAct.invitedAct + " has been removed.");
            this.handleCloseDeleteModal();
        });
    };

    handleOpenDeleteModal = agentAct => {
        this.setState({
            ...this.state,
            deleteActModalState: true,
            deletedAct: agentAct
        });
    };

    handleOpenDeleteRequestModal = agentAct => {
        this.setState({
            ...this.state,
            deleteRequestModalState: true,
            deletedAct: agentAct
        });
    };

    handleOpenDeleteInvitationModal = agentAct => {
        this.setState({
            ...this.state,
            deleteInvitationModalState: true,
            deletedAct: agentAct
        });
    };

    handleCloseDeleteModal = () => {
        this.setState({
            ...this.state,
            deleteActModalState: false,
            deletedAct: undefined,
            deleteRequestModalState: false,
            deleteInvitationModalState: false,
            declineInvitationState: false
        });
    };

    handleDeclineInvitation = (invitationId, actName) => {
        this.props.userInvitationStore.declineUserInvitation(invitationId).then(() => {
            this.props.userInvitationStore.resetInvitations();
            this.props.agentActStore.resetAgentActs();
            Promise.all([
                this.props.agentActStore.getAgentsPendingRequest(),
                this.props.agentActStore.getAgentActRoster(),
                this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentAct.ordinal)
            ]).then(() => { 
                this.props.templateStore.openSnackbar(actName + " has been declined.");
                this.handleCloseDeleteModal();
            });
        });
    }

    handleAcceptInvitation = (data) => {
        this.props.agentActStore.acceptInvitation(data).then(() => {
            this.props.userInvitationStore.resetInvitations();
            this.props.agentActStore.resetAgentActs();
            Promise.all([
                this.props.agentActStore.getAgentsPendingRequest(),
                this.props.agentActStore.getAgentActRoster(),
                this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentAct.ordinal)
            ]).then(() => { 
                this.props.templateStore.openSnackbar(data.invitedAct + " has been accepted.");
                this.handleCloseDeleteModal();
            });
            
        });
    }

    handleAcceptRequest = request => {
        this.props.agentActStore.acceptRequest(request);
    };

    handleDeclineRequest = request => {
        this.props.agentActStore.declineRequest(request);
    };

    render() {
        this.mapInviteRequests().then(() => {
            this.mapPendingRequest();
        });
        this.mapAccepted();

        const { isLoadingPendingActs, isLoadingAcceptedActs, actInvitationModalState } = this.props.agentActStore;
        const { isLoadingUserInvitations } = this.props.userInvitationStore;

        return (
            <Fragment>
                <Paper style={{ padding: 30, borderTopLeftRadius: 0, borderTopRightRadius: 0, }}>
                    {/* <Heading title="My Act Roster">
                    <Button id="btn-invite-act2" color="primary" className="pull-right" onClick={this.openActInvitationModal}>
                        Invite Act
                    </Button>
                </Heading> */}
                    {this.pendingRows.length > 0 ? (
                        <Fragment>
                            <div style={{ position: "relative" }}>
                                <Loading showed={isLoadingPendingActs || isLoadingUserInvitations} />
                                <ResponsiveTable
                                    columns={this.pendingColumns}
                                    rows={this.pendingRows}
                                    showToolbar={true}
                                    title="Pending Acts"
                                    pagination={this.pendingRows.length > 5}
                                />
                            </div>
                            <br />
                        </Fragment>
                    ) : (
                        ""
                    )}

                    <div style={{ position: "relative" }}>
                        <Loading showed={isLoadingAcceptedActs || isLoadingUserInvitations} />
                        <ResponsiveTable title="Approved Acts" sortable={true} showToolbar={true} columns={this.columns} rows={this.rows} />
                    </div>
                </Paper>

                <ActInvitationModal
                    open={actInvitationModalState}
                    onClose={this.closeActInvitationModal}
                    profileType="agent"
                    handleEmpty={this.openActOwnerInvitationModal}
                />
                <ActOwnerInvitationModal open={this.state.actOwnerInvitationModalState} onClose={this.closeActOwnerInvitationModal} />

                <ConfirmationModal
                    open={this.state.deleteInvitationModalState}
                    message="Are you sure you want to remove this act?"
                    closeHandler={this.handleCloseDeleteModal}
                    confirmationHandler={this.handleDeleteInvitation}
                    declineHandler={this.handleCloseDeleteModal}
                    confirmationLabel="Remove"
                />

                <ConfirmationModal
                    open={this.state.deleteRequestModalState}
                    message="Are you sure you want to remove this act?"
                    closeHandler={this.handleCloseDeleteModal}
                    confirmationHandler={this.handleDeleteRequest}
                    declineHandler={this.handleCloseDeleteModal}
                    confirmationLabel="Remove"
                />

                <ConfirmationModal
                    open={this.state.deleteActModalState}
                    message="Are you sure you want to remove this act? Your current bookings with this act will be removed."
                    closeHandler={this.handleCloseDeleteModal}
                    confirmationHandler={this.handleDeleteAct}
                    declineHandler={this.handleCloseDeleteModal}
                    confirmationLabel="Remove"
                />
            </Fragment>
        );
    }
}

export default AgentActRoster;
