import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import profileStore from "./profileStore";

import _ from "lodash";
import { Promise } from "bluebird";

import { ProfileType, ProfileRelationType } from "../types/enum";

class AgentActStore {
    @observable pendingAgentActs = [];
    @observable agentActs = [];

    @observable invitedAct = undefined;
    @observable invitationMessage = "";

    @observable invitedActs = [];
    @observable isLoading = false;
    @observable isLoadingPendingActs = false;
    @observable isLoadingAcceptedActs = false;

    @observable actInvitationModalState = false;
    @observable isAgentAct = false;
    @observable invitedChecked = [];

    @action
    resetData() {
        this.pendingAgentActs = [];
        this.agentActs = [];
        this.invitedAct = undefined;
        this.invitationMessage = "";
        this.invitedActs = [];
        this.isLoading = false;
    }

    @action
    resetAgentActs() {
        this.agentActs = [];
    }

    @action
    getAgentAvailableActs(booking, agentId) {
        const { profile } = profileStore;

        if (!agentId) {
            agentId = profile.id;
        }
        return agent.AgentAct.getAgentAvailableActs(agentId, booking).then(
            action(async response => {
                var result = [];
                for (var i = 0; i < response.length; i++) {
                    var data = response[i];
                    await profileStore.mapAdditionalProfileDataAsync(data.act);
                    await profileStore.mapAdditionalProfileDataAsync(data.venue);
                    // profileStore.mapAdditionalProfileData(data.act);
                    // profileStore.mapAdditionalProfileData(data.venue);
                    if (data.act.myMusician) {
                        await profileStore.mapAdditionalProfileDataAsync(data.act.myMusician);
                        // profileStore.mapAdditionalProfileData(data.act.myMusician);
                    }
                    result.push(data);
                }
                this.agentActs = result;
                return this.agentActs;
            })
        );
    }

    @action
    searchAgentActByActName(actName) {
        return this.getAgentActRoster().then(response => {
            var result = [];
            response.forEach(data => {
                console.log(data.act.fullName)
                if (data.act.fullName.toLowerCase().includes(actName.toLowerCase())) {
                    result.push(data.act);
                }
            });
            return result;
        });
    }

    //Pending Request
    @action
    getAgentsPendingRequest() {
        this.isLoadingPendingActs = true;
        return agent.ProfileRelationship.getAgentActRequest()
            .then(
                action(async response => {
                    this.pendingAgentActs = []
                    for (let data of response) {
                        await profileStore.mapAdditionalProfileData(data.primaryProfile);
                        await profileStore.mapAdditionalProfileData(data.secondaryProfile);

                        if (data.primaryProfile.type === ProfileType.Act.ordinal && data.primaryProfile.myMusician) {
                            await profileStore.mapAdditionalProfileData(data.primaryProfile.myMusician);
                        } else if (data.secondaryProfile.type === ProfileType.Act.ordinal && data.secondaryProfile.myMusician) {
                            await profileStore.mapAdditionalProfileData(data.secondaryProfile.myMusician);
                        }
                        data.venueName = "Loading...";
                        runInAction(() => {
                            this.pendingAgentActs.push(data)
                        })
                    };

                    const venues = response.reduce((x, y) => {
                        if (y && y.rules) {
                            const rules = JSON.parse(y.rules);
                            if (rules.VenueId) {
                                x.push(rules.VenueId);
                            }
                        }
                        return x;
                    }, []);

                    agent.Venue.getSummaries(venues).then(
                        action(response => {
                            runInAction(() => {
                                this.pendingAgentActs.map(pendingAct => {
                                    const rules = JSON.parse(pendingAct.rules);
                                    if (rules && rules.VenueId) {
                                        const venue = response.filter(x => x.id === rules.VenueId);
                                        if (venue && venue.length > 0) {
                                            pendingAct.venueName = venue[0].profileName;
                                        }
                                    }
                                    return pendingAct;
                                });
                            })
                        })
                    );

                    return this.pendingAgentActs;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingPendingActs = false;
                })
            );
    }

    @action
    getActPendingRequest() {
        agent.ProfileRelationship.getActAgentRequest().then(
            action(response => {
                this.pendingAgentActs = response;
            })
        );
    }

    @action
    getMusicianActPendingRequest() {
        return agent.ProfileRelationship.getMusicianActAgentRequest().then(
            action(response => {
                this.pendingAgentActs = response.map(data => {
                    profileStore.mapAdditionalProfileData(data.primaryProfile);
                    profileStore.mapAdditionalProfileData(data.secondaryProfile);

                    if (data.primaryProfile.type === ProfileType.Act.ordinal && data.primaryProfile.myMusician) {
                        profileStore.mapAdditionalProfileData(data.primaryProfile.myMusician);
                    } else if (data.secondaryProfile.type === ProfileType.Act.ordinal && data.secondaryProfile.myMusician) {
                        profileStore.mapAdditionalProfileData(data.secondaryProfile.myMusician);
                    }
                    return data;
                });

                this.pendingAgentActs = _.chain(this.pendingAgentActs)
                    .groupBy("primaryProfileId")
                    .toPairs()
                    .map(function (pair) {
                        return _.zipObject(["agent", "request"], pair);
                    })
                    .value();
                return this.pendingAgentActs;
            })
        );
    }

    @action
    getAgentMyActsForMyGigs(agentId) {
        const { profile } = profileStore;

        if (!agentId) {
            agentId = profile?.id;
        }
        this.isLoadingAcceptedActs = true;
        return agent.AgentAct.getAgentActMyActs(agentId)
            .then(
                action(async response => {
                    // this.agentActs = [];
                    let result = [];

                    for (let data of response) {
                        await this.mapAdditionalProfileData(data.act);
                        await this.mapAdditionalProfileData(data.agent);
                        await this.mapAdditionalProfileData(data.act?.myMusician);
                        runInAction(() => {
                            result.push(data)
                            this.agentActs.push(data)
                        })
                    }
                    return result;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingAcceptedActs = false;
                })
            );
    }

    @action
    getAgentMyActs(agentId) {
        const { profile } = profileStore;

        if (!agentId) {
            agentId = profile?.id;
        }
        this.isLoadingAcceptedActs = true;
        return agent.AgentAct.getAgentActMyActs(agentId)
            .then(
                action(async response => {
                    // this.agentActs = [];
                    for (let data of response) {
                        await this.mapAdditionalProfileData(data.act);
                        await this.mapAdditionalProfileData(data.agent);
                        await this.mapAdditionalProfileData(data.act?.myMusician);
                        runInAction(() => {
                            this.agentActs.push(data)
                        })
                    }                  
                    return this.agentActs;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingAcceptedActs = false;
                })
            );
    }

    //Accepted Request
    @action
    getAgentActRoster(agentId) {
        const { profile } = profileStore;

        if (!agentId) {
            agentId = profile?.id;
        }
        this.isLoadingAcceptedActs = true;
        return agent.AgentAct.getAgentActRoster(agentId)
            .then(
                action(async response => {
                    // this.agentActs = [];
                    for (let data of response) {
                        await this.mapAdditionalProfileData(data.act);
                        await this.mapAdditionalProfileData(data.agent);
                        await this.mapAdditionalProfileData(data.act.myMusician);
                        runInAction(() => {
                            this.agentActs.push(data)
                        })
                    }
                    return this.agentActs;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingAcceptedActs = false;
                })
            );
    }

    @action
    getAgentActRosterSimple(agentId) {
        const { profile } = profileStore;

        if (!agentId) {
            agentId = profile?.id;
        }
        this.isLoadingAcceptedActs = true;
        return agent.AgentAct.getAgentActRosterSimple(agentId)
            .then(
                action(async response => {
                    // this.agentActs = [];
                    for (let data of response) {
                        await this.mapAdditionalProfileData(data.act);
                        await this.mapAdditionalProfileData(data.agent);
                        runInAction(() => {
                            this.agentActs.push(data)
                        })
                    }
                    return this.agentActs;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingAcceptedActs = false;
                })
            );
    }
    @action
    addAgentActListToAgentActs() {
        const { profile } = profileStore;

        return profileStore.getAgentActList()
        .then(
            action(async response => {
                await profileStore.mapAdditionalProfileDataAsync(profile)
                if (response.length > 0) {
                    for(let i = 0; i < response.length; i++) {
                        let act = response[i];
                        act.myMusician = profile;
                        runInAction(() => {
                            let data = {
                                act: act,
                                agent: profile,
                                actId: act.id,
                                agentId: profile.id,
                                id: null,
                                deleted: act.deleted
                            }
                            if (!this.agentActs.find(x => x.actId == data.actId)) {
                                this.agentActs.push(data)
                            }
                        })
                    }
                }
            })
        )
    }

    @action
    getAgentActRosterForMyGigs(agentId) {
        const { profile } = profileStore;

        if (!agentId) {
            agentId = profile?.id;
        }
        this.isLoadingAcceptedActs = true;
        return agent.AgentAct.getAgentActRoster(agentId)
            .then(
                action(async response => {
                    let result = [];
                    for (const data of response) {
                        await this.mapAdditionalProfileData(data.act);
                        await this.mapAdditionalProfileData(data.agent);
                        await this.mapAdditionalProfileData(data.act.myMusician);
                        runInAction(() => {
                            result.push(data);
                            this.agentActs = result;
                        })
                    }
                    return result
                })
            )
            .finally(
                action(() => {
                    this.isLoadingAcceptedActs = false;
                })
            );
    }

    @action
    getConsultantActRoster(consultantId) {
        const { profile } = profileStore;

        if (!consultantId) {
            consultantId = profile.id;
        }

        return agent.AgentAct.getConsultantActRoster(consultantId).then(
            action(async response => {
                // this.agentActs = [];
                for (let data of response) {
                    await this.mapAdditionalProfileData(data.act);
                    await this.mapAdditionalProfileData(data.agent);
                    await this.mapAdditionalProfileData(data.act?.myMusician);
                    runInAction(() => {
                        this.agentActs.push(data)
                    })
                }                   
                return this.agentActs;
            })
        );
    }

    @action
    getActAgents(actId) {
        agent.AgentAct.getActAgents(actId).then(
            action(response => {
                this.agentActs = response;
            })
        );
    }

    @action
    getMusicianAgents(musicianId) {
        const { profile } = profileStore;

        if (!musicianId) {
            musicianId = profile.id;
        }

        return agent.AgentAct.getMusicianAgents(musicianId).then(
            action(async response => {
                if (response && response.length > 0 && response !== undefined) {
                    var mapped = [];
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i]
                        await profileStore.mapAdditionalProfileDataAsync(data.agent);
                        await profileStore.mapAdditionalProfileDataAsync(data.act);
                        mapped.push(data)
                    }
                    runInAction(() => {
                        this.agentActs = mapped
                        this.agentActs = _.chain(this.agentActs)
                            .groupBy("agentId")
                            .toPairs()
                            .map(function (pair) {
                                return _.zipObject(["agent", "data"], pair);
                            })
                            .value();
                        return this.agentActs;
                    })
                } else {
                    this.agentActs = []
                    return mapped;
                }
            })
        )
    }

    @action
    acceptInvitation(invitation) {
        const { profile } = profileStore;
        const requestDeatail = {
            primaryProfileId: profile.id,
            secondaryProfileId: Number(invitation.additionalData),
            requestMessage: invitation.invitationMessage,
            type: ProfileRelationType.AgentAct.ordinal,
            createdById: Number(invitation.invitedVenue),
            invitationId: invitation.id
        }
        return agent.ProfileRelationship.invite(requestDeatail).then(
            action(response => {
                this.getAgentsPendingRequest()
            })
        )
    }

    @action
    inviteMany(checkedOnly) {
        const { profile } = profileStore;

        if (this.invitedActs && this.invitedActs.length > 0) {
            var requestDetails = [];
            var listActs = checkedOnly ? this.invitedChecked : this.invitedActs;
            listActs.forEach(invitedAct => {
                requestDetails.push({
                    primaryProfileId: profile.id,
                    secondaryProfileId: invitedAct.id,
                    requestMessage: this.invitationMessage,
                    type: ProfileRelationType.AgentAct.ordinal,
                    createdById: profile.id
                });
            });
            return agent.ProfileRelationship.inviteMany(requestDetails).then(
                action(response => {
                    this.resetAgentActs();
                    this.getAgentsPendingRequest();
                    this.getAgentActRoster();
                    this.getAgentMyActs()
                    return this.pendingAgentActs;
                })
            );
        }

        return Promise.resolve();
    }

    @action
    setInvitedChecked(acts) {
        this.invitedChecked = acts;
    }

    @action
    setInvitedActs(acts) {
        this.invitedActs = acts;
    }

    @action
    setInvitationMessage(value) {
        this.invitationMessage = value;
    }

    @action
    setInvitedAct(act) {
        // if the act is coming from the search page, it will be a skeleton act. We will need to load the act details.
        if (!act.myMusician) {
            this.isLoading = true;
            this.invitedAct = []
            return agent.Profile.loadActs(act.userId)
                .then(
                    action(async musician => {
                        await profileStore.mapAdditionalProfileDataAsync(musician.myMusician);
                        if(musician.myActs.length > 0 && Array.isArray(musician.myActs)){
                            for(let i = 0; i < musician.myActs.length; i++) {
                                await profileStore.mapAdditionalProfileDataAsync(musician.myActs[i])
                                musician.myActs[i].selectedActId = act.id
                            }
                        }
                        act.myMusician = musician.myMusician
                        act.myActs = musician.myActs
                        runInAction(() => {
                            this.invitedAct = act;
                            return this.invitedAct
                        })
                    })
                )
                .finally(
                    action(() => {
                        this.isLoading = false;
                    })
                );
        } else {
            profileStore.mapAdditionalProfileData(act.myMusician);
            if (act.myMusician && act.myMusician.myActs) {
                act.myMusician.myActs = act.myMusician.myActs.map(data => {
                    profileStore.mapAdditionalProfileData(data);
                    return data;
                });
            }

            this.invitedAct = act;
            if (!this.invitedActs) {
                this.invitedActs = [];
            }
            this.invitedActs = act.myMusician.myActs
            // this.invitedActs.push(act);
        }
    }

    @action
    clearInviteData() {
        this.invitationMessage = "";
        this.invitedAct = undefined;
        this.invitedActs = [];
    }

    //end of invitation

    @action
    acceptRequest(request) {
        const { currentProfile } = profileStore;
        return agent.ProfileRelationship.accept(request).then(
            action(response => {
                if (currentProfile.type === ProfileType.Musician.ordinal) {
                    this.getMusicianActPendingRequest();
                    this.getMusicianAgents();
                } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                    this.resetAgentActs();
                    this.getAgentsPendingRequest();
                    this.getAgentActRoster();
                }
                return response;
            })
        );
    }

    @action
    acceptManyRequest(requests) {
        var data = [];
        requests.forEach(request => {
            data.push(request);
        });
        return agent.ProfileRelationship.acceptMany(data).then(
            action(response => {
                //TODO: delete and add based on response
                this.getMusicianActPendingRequest();
                this.getMusicianAgents();
                return response;
            })
        );
    }

    @action
    removeRequest(request) {
        const { profile } = profileStore;
        return agent.ProfileRelationship.remove(request).then(
            action(response => {
                if (profile.type === ProfileType.Musician.ordinal) {
                    this.getMusicianActPendingRequest();
                    this.getMusicianAgents();
                } else if (profile.type === ProfileType.Agent.ordinal) {
                    this.resetAgentActs();
                    this.getAgentsPendingRequest();
                    this.getAgentActRoster();
                }
                return response;
            })
        );
    }

    @action
    declineRequest(request) {
        const { profile } = profileStore;
        return agent.ProfileRelationship.decline(request).then(
            action(response => {
                if (profile.type === ProfileType.Musician.ordinal) {
                    this.getMusicianActPendingRequest();
                    this.getMusicianAgents();
                } else if (profile.type === ProfileType.Agent.ordinal) {
                    this.resetAgentActs();
                    this.getAgentsPendingRequest();
                    this.getAgentActRoster();
                }
                return response;
            })
        );
    }

    @action
    declineManyRequest(requests) {
        const { profile } = profileStore;
        var data = [];
        requests.forEach(request => {
            data.push(request.id);
        });
        return agent.ProfileRelationship.declineMany(data).then(
            action(response => {
                if (profile.type === ProfileType.Musician.ordinal) {
                    this.getMusicianActPendingRequest();
                    this.getMusicianAgents();
                } else if (profile.type === ProfileType.Agent.ordinal) {
                    this.resetAgentActs();
                    this.getAgentsPendingRequest();
                    this.getAgentActRoster();
                }
                return response;
            })
        );
    }

    @action
    removeAgentAct(agentActId) {
        const { profile } = profileStore;
        return agent.AgentAct.delete(agentActId).then(
            action(response => {
                if (profile.type === ProfileType.Musician.ordinal) {
                    this.getMusicianActPendingRequest();
                    this.getMusicianAgents();
                } else if (profile.type === ProfileType.Agent.ordinal) {
                    this.resetAgentActs();
                    this.getAgentsPendingRequest();
                    this.getAgentActRoster();
                    if (!profile.isConsultant){
                        this.addAgentActListToAgentActs()
                    }
                }
            })
        );
    }

    @action
    removeAgentActs(agentActs) {
        const { profile } = profileStore;

        var data = [];
        agentActs.forEach(agentAct => {
            data.push(agentAct.id);
        });

        return agent.AgentAct.deleteMany(data).then(
            action(response => {
                if (profile.type === ProfileType.Musician.ordinal) {
                    this.getMusicianActPendingRequest();
                    this.getMusicianAgents();
                } else if (profile.type === ProfileType.Agent.ordinal) {
                    this.resetAgentActs();
                    this.getAgentsPendingRequest();
                    this.getAgentActRoster();
                }
            })
        );
    }

    @action
    getLineUpCounts(instrument) {
        if (!instrument || instrument === 0) {
            return "";
        }

        let lineUpCounts = [
            { name: "Solo", instrument: 1 },
            { name: "Duo", instrument: 2 },
            { name: "Trio", instrument: 3 },
            { name: "Quartet / 4 Piece", instrument: 4 },
            { name: "Quintet / 5 Piece", instrument: 5 },
            { name: "Sextet / 6 Piece", instrument: 6 }
        ];

        for (var i = 0; i < 93; i++) {
            lineUpCounts.push({ name: i + 7 + " Piece", instrument: i + 7 });
        }

        return lineUpCounts.filter(lineUpCount => lineUpCount.instrument === instrument)[0].name;
    }

    @action
    openActInvitationModal() {
        this.actInvitationModalState = true;
        this.clearInviteData();
    }

    @action
    closeActInvitationModal() {
        this.actInvitationModalState = false;
    }

    @action
    async mapAdditionalProfileData(profile) {
        await profileStore.mapAdditionalProfileImageAndBackgroundImages(profile);
        profileStore.mapAdditionalProfileNames(profile);
    }

    @action
    setIsAgentAct(value) {
        this.isAgentAct = value;
    }
}

export default new AgentActStore();
