import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Breadcrumb from "../../components/Breadcrumb";
import AgentActRoster from "../../components/agent/AgentActRoster";
import FestivalActRoster from "../../components/agent/FestivalActRoster";
import { Tabs, Tab, Hidden } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Loading from "../../components/loadingSpinner/Loading";
import { Button } from "@material-ui/core";
import Heading from "../../components/Heading";
import { ProfileRelationType, ProfileType } from "../../types/enum";
import ActList from "../../components/acts/ActList";
@inject("profileStore", "agentActStore", "userInvitationStore")
@withRouter
@observer
class ActRoster extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/agent" }, { name: "My Act Roster" }];
    state = {
        tabValue: 0,
        mainTabValue: 0,
    };

    componentDidMount = () => {
        this.profile = this.props.profileStore.getCurrentProfile();
        this.props.agentActStore.setIsAgentAct(false);
        this.props.agentActStore.resetAgentActs();
        Promise.all([
            this.props.profileStore.loadProfiles().then(() => {
                // this.props.userInvitationStore.resetInvitations(),
                this.props.agentActStore.getAgentsPendingRequest(),
                this.props.agentActStore.getAgentActRoster(),
                this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentAct.ordinal)
                // if (this.profile?.type == ProfileType.Agent.ordinal && !this.profile?.isConsultant) {
                //     this.props.agentActStore.addAgentActListToAgentActs();
                // }
            })
        ]).then(() => { });

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.tab) {
            if (this.props.match.params.tab === "default") {
                this.setState({
                    tabValue: 0
                });
            } else if (this.props.match.params.tab === "festival") {
                this.setState({
                    tabValue: 1
                });
            } else if (this.props.match.params.tab === "myact") {
                this.setState({
                    tabValue: 2
                });
            }
        }
    };

    renderTabComponent = () => {
        const { tabValue } = this.state;

        switch (tabValue) {
            case 0:
                return <AgentActRoster />;
            case 1:
                return <FestivalActRoster />;
            case 2:
                return <>
                    <div style={{ paddingTop: 30 }}>
                        <ActList agentAct />
                    </div>
                </>
            default:
                return "";
        }
    };

    handleTabChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.replace("/agent/actroster");
                break;
            case 1:
                this.props.history.replace("/agent/actroster/festival");
                break;
            case 2:
                this.props.history.replace("/agent/actroster/myact");
                break;
            default:
                this.props.history.replace("/agent/actroster/default");
                break;
        }

        this.setState({
            tabValue: value
        });
    };

    openActInvitationModal = () => {
        this.props.agentActStore.openActInvitationModal();
    };

    closeActInvitationModal = () => {
        this.props.agentActStore.closeActInvitationModal();
    };

    render() {
        const { isLoading } = this.state;
        var currentProfile = this.props.profileStore.getCurrentProfile();
        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading title="My Act Roster">
                        {this.state.tabValue === 2 ?
                            (currentProfile?.isConsultant ? null :  <Button id="btn-invite-act2" variant="contained" color="primary" style={{color: 'white'}} onClick={() => this.props.agentActStore.setIsAgentAct(true)} className="pull-right" component={Link} to="/myact/create">
                            Create Act
                        </Button>)
                            :
                            <Button id="btn-invite-act2" variant="contained" color="primary" className="pull-right" onClick={this.openActInvitationModal}>
                                Add Act
                        </Button>}
                    </Heading>
                    <div style={{ backgroundColor: 'white' }}>
                        <Tabs value={this.state.tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab style={{ fontSize: 14 }} value={0} label="Agency Act Roster" />
                            <Tab style={{ fontSize: 14 }} value={2} label="Agency Exclusive Acts" />
                        </Tabs>
                    </div>
                    {!this.profile?.festivalCode && this.renderTabComponent()}

                    {this.profile?.festivalCode && (
                        <Paper style={{ position: "relative" }} className="booking-paper-form">
                            <Loading showed={isLoading} />
                            <div style={{ padding: 16 }}>
                                <Hidden smDown>
                                    <Tabs
                                        value={this.state.tabValue}
                                        onChange={this.handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        scrollable
                                        scrollButtons="off"
                                    >
                                        <Tab label="All Acts" />
                                        <Tab label={`${this.profile?.festivalTitle} Acts`} />
                                    </Tabs>
                                    <br />
                                </Hidden>
                                {this.renderTabComponent()}
                            </div>
                        </Paper>
                    )}
                </MainContainer>
            </main>
        );
    }
}

export default ActRoster;
