import { observable, action } from "mobx";
import agent from "../agent";
import userStore from "./userStore";
import userProfile from "./profileStore";
import commonStore from "./commonStore";
import templateStore from "./templateStore";
import notificationStore from "./notificationStore";
import billingStore from "./billingStore";
import reportStore from "./reportStore";
import jwtDecode from "jwt-decode";
import _superagent from "superagent";
import { Auth } from "aws-amplify";
import CacheHelper from "../helper/cache";
import profileStore from "./profileStore";
const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class AuthStore {
    @observable inProgress = false;
    @observable redirectUrl = undefined;
    // the errors are returned in capitals
    @observable
    errors = {
        email: "",
        firstName: "",
        lastName: "",
        type: "",
        password: "",
        confirmPassword: "",
        newPassword: "",
        confirmationToken: "",
        source: ""
    };

    @observable
    values = {
        email: "",
        firstName: "",
        lastName: "",
        type: "",
        password: "",
        confirmPassword: "",
        newPassword: "",
        confirmationToken: "",
        source: ""
    };

    @action
    setUsername(username) {
        this.values.username = username;
    }

    @action
    setFirstname(firstName) {
        this.values.firstName = firstName;
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors["firstName"] = ""
    }

    @action
    validateFirstName(firstName) {
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        if (this.values.firstName.length === 0) {
            this.errors["firstName"] = "First name should not be empty"
        } else {
            this.errors["firstName"] = ""
        }
    }

    @action
    setLastname(lastName) {
        this.values.lastName = lastName;
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors["lastName"] = ""
    }

    @action
    validateLastName(lastName) {
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        if (this.values.lastName.length === 0) {
            this.errors["lastName"] = "Last name should not be empty"
        } else {
            this.errors["lastName"] = ""
        }
    }

    @action
    setEmail(email) {
        this.values.email = email;
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors["email"] = "";
    }

    @action
    validateEmail(email) {
        var email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        /* eslint-enable no-useless-escape*/
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        if (!email_regex.test(email) && this.values.email.length > 0) {
            this.errors.email = "Invalid Email"
        } else {
            this.errors["email"] = "";
        }
    }

    @action
    setSource(source) {
        this.values.source = source;
    }

    @action
    setPassword(password) {
        this.values.password = password;
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors.password = ""
    }

    @action
    validatePassword(password) {
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        // var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+\\/^#$@!%*?&()-]{8,30}$/
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+\\/^#$@!%*?&()-]{8,30}$/

        if (!regex.test(password) && this.values.password.length > 0) {
            this.errors["password"] = "Your password must have at least one number, one capital, and  at least 8 characters long."
        } else {
            this.errors.password = ""
        }
        if (password !== this.values.confirmPassword && this.values.confirmPassword.length > 0) {
            this.errors["confirmPassword"] = "Password and Confirm Password do not match. Please try again."
        } else {
            this.errors.confirmPassword = ""
        }
    }

    @action
    setConfirmPassword(confirmPassword) {
        this.values.confirmPassword = confirmPassword;
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors.confirmPassword = ""
    }

    @action
    validateConfirmPasswor(confirmPassword) {
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        if ((confirmPassword !== this.values.password) && (confirmPassword !== this.values.newPassword)) {
            this.errors["confirmPassword"] = "Password and Confirm Password do not match. Please try again."
        } else {
            this.errors.confirmPassword = ""
        }
    }

    @action
    setNewPassword(newPassword) {
        this.values.newPassword = newPassword;
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        this.errors.newPassword = ""
    }

    @action
    validateNewPassword(newPassword) {
        if (this.errors === undefined || this.errors === 'undefined') this.resetErrors()
        // var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&()-]{8,30}$/
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+\\/^#$@!%*?&()-]{8,30}$/

        if (!regex.test(newPassword) && this.values.newPassword.length > 0) {
            this.errors["newPassword"] = "Your New Password must have at least one number, one capital, and  at least 8 characters long."
        } else {
            this.errors.newPassword = ""
        }
        if (newPassword !== this.values.confirmPassword && this.values.confirmPassword.length > 0) {
            this.errors["confirmPassword"] = "Password and Confirm Password do not match. Please try again."
        } else {
            this.errors.confirmPassword = ""
        }
    }

    @action
    setAccountType(type) {
        this.values.type = type;
    }

    @action
    setConfirmationToken(confirmationToken) {
        this.values.confirmationToken = confirmationToken;
    }

    @action
    reset() {
        this.values.email = "";
        this.values.firstName = "";
        this.values.lastName = "";
        this.values.type = "";
        this.values.password = "";
        this.values.confirmPassword = "";
        this.values.newPassword = "";
        this.values.confirmationToken = "";
        // this.resetErrors()
        this.errors = undefined;
    }

    @action
    resetErrors() {
        this.errors = {
            email: "",
            firstName: "",
            lastName: "",
            type: "",
            password: "",
            confirmPassword: "",
            newPassword: "",
            confirmationToken: "",
            source: ""
        }
    }

    @action
    async forgotPassword() {
        this.inProgress = true;
        try {
            await Auth.forgotPassword(this.values.email)
        }
        catch (err) {
            console.log("failed", err)
        }
    }

    async registerUser() {
        this.inProgress = true;
        try {
            if(!this.values.lastName){
                var fields = this.values.firstName.split(' ');
                if (fields.length > 1){
                    this.setLastname(fields[fields.length-1])
                    let removeLastIndex = fields.pop()
                    this.setFirstname(fields.toString())
                }else {
                    this.setLastname(" ")
                }
            }
            const { user } = await Auth.signUp({
                username: this.values.email.trim(), password: this.values.password,
                attributes: {
                    email: this.values.email.trim(),
                    given_name: this.values.firstName,
                    family_name: this.values.lastName,
                    "custom:status": '0',
                }
            });
            return user;
        }
        catch (err) {
            throw err.message;
        }
    }
    loginAndUpdatePassword(token, as) {
        var username = this.values.email;
        var tempPassword = token;
        var newPassword = this.values.password;
        return Auth.signIn(username, tempPassword)
            .then(user => {
                if (user.signInUserSession !== null) {
                    var authToken = user.signInUserSession.accessToken.jwtToken;
                    var refreshToken = user.signInUserSession.refreshToken.token;
                    userStore.saveUser(user);
                    commonStore.setToken(authToken, refreshToken);
                }

                if (user.challengeName === 'NEW_PASSWORD_REQUIRED' || as === "changeEmail") {
                    if (as && as !== "changeEmail") {
                        var status = '0'
                        if ((as === "staff" || as === "AgentVenues" || as === "VenuesAgent" || as === "consultant" || as === "inviteAgentStaff")) {
                            status = '50'
                        }
                    }
                    
                    return Auth.completeNewPassword(user, newPassword, {
                        given_name: this.values.firstName,
                        family_name: this.values.lastName,
                        "custom:status": status,
                    })
                        .then(user => {
                            
                            return Auth.signIn(username, newPassword).then(response => {
                                var authToken = response.signInUserSession.accessToken.jwtToken;
                                var refreshToken = response.signInUserSession.refreshToken.token;
                                userStore.saveUser(response);
                                commonStore.setToken(authToken, refreshToken);
                                if (as && as == "changeEmail") {
                                   billingStore.updateCustomerEmail({userId: userStore.currentUser.sub,newEmail: username})
                                }
                                return response;
                            });
                        });
                }
            });
    }
    checkValidationLogin() {
        var validationErrors = {};
        var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        
        if (this.values.email.length == 0) {
            validationErrors.email = "Email should not be empty";
        }
        if (this.values.password.length == 0) {
            validationErrors.password = "Password should not be empty";
        }
        return validationErrors;
    }

 
    checkValidationRegister() {
        var validationErrors = {};
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+\\/^#$@!%*?&()-]{8,30}$/

        if (this.values.email.length == 0) {
            validationErrors.email = "Email should not be empty";
        }
        if (this.values.firstName.length == 0) {
            validationErrors.firstName = "First name should not be empty";
        }
        if (this.values.lastName.length == 0) {
            validationErrors.lastName = "Last name should not be empty";
        }
        if (this.values.password !== this.values.confirmPassword) {
            validationErrors.password = "Password and Confirm Password do not match. Please try again.";
            validationErrors.confirmPassword = "Password and Confirm Password do not match. Please try again.";
        }
        if (!regex.test(this.values.password)) {
            validationErrors.password = "Your password must have at least one number, one capital, and  at least 8 characters long.";
        }
        if (this.values.password.length == 0) {
            validationErrors.password = "Password should not be empty";
        }
        if (this.values.confirmPassword.length == 0) {
            validationErrors.confirmPassword = "Confirm password should not be empty";
        }
        return validationErrors;
    }

    checkValidationReregister() {
        var validationErrors = {};
        // var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+\\/^#$@!%*?&()-]{8,30}$/

        if (this.values.email.length == 0) {
            validationErrors.email = "Email should not be empty";
        }
        if (this.values.password !== this.values.confirmPassword) {
            validationErrors.password = "Password and Confirm Password do not match. Please try again.";
            validationErrors.confirmPassword = "Password and Confirm Password do not match. Please try again.";
        }
        if (!regex.test(this.values.password)) {
            validationErrors.password = "Your password must have at least one number, one capital, and  at least 8 characters long.";
        }
        if (this.values.password.length == 0) {
            validationErrors.password = "Password should not be empty";
        }
        if (this.values.confirmPassword.length == 0) {
            validationErrors.confirmPassword = "Confirm password should not be empty";
        }
        return validationErrors;
    }

    @action
    async getMappingUserDataInit(invitationCode) {
        return agent.Auth.getDataMappingUserInit(invitationCode).then((response) => {

            return response;
        })
    }

    @action
    async checkEmailValid() {
        return agent.Profile.checkEmailValid(this.values.email.trim())
            .then(
                action(async isValid => {
                    return isValid
                })
            )
    }

    @action
    async checkEmailValidWithEmail(email) {
        return agent.Profile.checkEmailValid(email)
            .then(
                action(async isValid => {
                    return isValid
                })
            )
    }

    @action
    validate(){
        var validationErrors = this.checkValidationReregister();
        if (Object.keys(validationErrors).length !== 0) {
            this.errors = validationErrors;
            throw validationErrors;
        }
    }
    
    @action
    reregister(invitationCode) {
        return this.registerUser()
            .then(registeredUser => {
                console.log(registeredUser);
                return agent.Auth.reregister(
                    this.values.email.trim(),
                    invitationCode

                ).then(user => {
                    // commonStore.setToken(user.authToken, user.refreshToken);
                    templateStore.setActiveSidebarKey("dashboard");
                    // userStore.pullUser();
                    user.isInvited = true;
                    return billingStore.getPaymentAccesses(user.newUserId).then(() => {
                        return user;
                    });
                })
                    // .then(user => {
                    //     return userStore.pullUser().then(response => {
                    //         response.profileId = profileId;
                    //         response.isInvited = true;
                    //         this.reset();
                    //         return response;
                    //     });
                    // })
                    // .catch(
                    //     action(err => {
                    //         this.errors = err.response && err.response.body && err.response.body.errors;
                    //         //this.errors = err.response.body;
                    //         throw err;
                    //     })
                    // )
            })
            .catch(
                action(err => {
                    this.errors = { general: err };
                    throw err;
                })
            ).finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }


    @action
    async register(token, source, as) {
        this.inProgress = true;
        // this.errors = undefined;
        this.resetErrors()
        var isInvited = false;
        var profileId = 0;

        var validationErrors = this.checkValidationRegister();
        if (Object.keys(validationErrors).length !== 0) {
            this.errors = validationErrors;
            throw validationErrors;
        }

        if (typeof token === "undefined") {
            return this.registerUser()
                .then(user => {
                    return user;
                })
                .catch(
                    action(err => {
                        this.errors = { general: err };
                        throw err;
                    })
                ).finally(
                    action(() => {
                        this.inProgress = false;
                    })
                );
        }
        else if (as === "changeEmail") {
            return this.loginAndUpdatePassword(token, as).then(response => {
                return userStore.pullUser().then(response => {
                    return profileStore.loadProfiles().then(res => {
                        response.profileId = profileId;
                        response.isInvited = true;
                        this.reset();
                        return response;
                    });

                });
            }).finally(
                action(() => {
                    this.inProgress = false;
                })
            );
        }
        return agent.Auth.register(
            this.values.email,
            this.values.firstName,
            this.values.lastName,
            this.values.password,
            this.values.confirmPassword,
            this.values.type,
            token,
            source
        )
            .then(user => {
                // commonStore.setToken(user.authToken, user.refreshToken);
                templateStore.setActiveSidebarKey("dashboard");

                // userStore.pullUser();
                isInvited = user.isInvited;
                profileId = user.profileId;
                user.isInvited = true;

                return billingStore.getPaymentAccesses(user.id).then(() => {
                    return user;
                });
            })
            .then(async user => {
                if (token) {
                    return this.loginAndUpdatePassword(token, as).then(response => {
                        return userStore.pullUser().then(response => {
                            return profileStore.loadProfiles().then(res => {
                                response.profileId = profileId;
                                response.isInvited = true;
                                this.reset();
                                return response;
                            });

                        });
                    })
                } else {
                    return userStore.pullUser().then(response => {
                        response.profileId = profileId;
                        response.isInvited = true;


                        this.reset();
                        return response;
                    });
                }
            })
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    //this.errors = err.response.body;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );

    }

    @action
    async login() {
        this.inProgress = true;
        this.errors = undefined;

        var validationErrors = this.checkValidationLogin();
        if (Object.keys(validationErrors).length !== 0) {
            this.errors = validationErrors;
            throw validationErrors;
        }

        return Auth.signIn(this.values.email, this.values.password).then(async user => {
            commonStore.resetData();
            var authToken = user.signInUserSession.accessToken.jwtToken;
            var refreshToken = user.signInUserSession.refreshToken.token;
            commonStore.setToken(authToken, refreshToken);
            templateStore.setActiveSidebarKey("dashboard");

            userStore.saveUserLogin(user)

            // await userStore.pullUser()
            //     .then(() => {
            //         userProfile.loadProfilesAfterLogin()
            //         billingStore.reloadAccessSynch();
            //     });
            await userStore.pullUser()
               .then(() => userProfile.loadProfilesAfterLogin());
                
            this.inProgress = false;
            return user;
        }).catch(
            action(error => {
                this.errors = { general: error.message };
                throw error;
            })
        );
    }

    @action
    loginWithToken(token, refreshToken) {
        this.inProgress = true;
        commonStore.setToken(token, refreshToken);
        templateStore.setActiveSidebarKey("dashboard");
        return userStore
            .pullUser()
            .then(() => userProfile.loadProfiles())
            .then(() => billingStore.getPaymentAccesses(userStore.currentUser.id))
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    async logout() {
        commonStore.setToken(undefined);
        commonStore.resetAllData();
        userStore.forgetUser();
        templateStore.setActiveSidebarKey(undefined);
        notificationStore.unsubscribe();
        CacheHelper.clearImageLinkCache();
        profileStore.reset();
        notificationStore.reset();
        billingStore.resetAccess();
        reportStore.resetFilterDataOnLogout();
        this.reset();
        // this.resetErrors()
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
        return Promise.resolve();
    }

    @action
    changePassword() {
        this.inProgress = true;
        // this.errors = undefined;
        this.resetErrors()
        return agent.Auth.changePassword(this.values.password, this.values.newPassword, this.values.confirmPassword)
            .then(response => {
                this.reset();
            })
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    //this.errors = err.response.body;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    async createNewPassword() {
        this.inProgress = true;
        this.resetErrors()
        try {
            await Auth.forgotPasswordSubmit(this.values.email, this.values.password, this.values.newPassword)
        }
        catch (error) {
            console.log("error", error)
        }
    }

    @action
    async confirmationToken() {
        try {
            this.inProgress = true;
            // this.errors = undefined;
            this.resetErrors()
            var response = await Auth.confirmSignUp(this.values.email, this.values.confirmationToken);
            // this.setCurrentUserVerified();
        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }

    @action
    resendConfirmationToken() {
        this.inProgress = true;
        // this.errors = undefined;
        this.resetErrors()
        return agent.Auth.resendConfirmationToken(this.values.email.trim())
            .then(response => {
                this.reset();
                return response;
            })
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action
    async refreshToken() {
        if (commonStore.token) {
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                if (cognitoUser) {
                    const currentSession = await Auth.currentSession();
                    cognitoUser.refreshSession(
                        currentSession.refreshToken,
                        (err, session) => { }
                    );
                    var authToken = cognitoUser.signInUserSession.accessToken.jwtToken;
                    var refreshToken = cognitoUser.signInUserSession.refreshToken.token;
                    commonStore.setToken(authToken, refreshToken);
                    userStore.saveUser(cognitoUser);
                    return cognitoUser;
                }
            } catch (error) {
                console.log("=> Error Refresh Current Cognito Session: ", error);
                return null;
            }
        }
    }

    @action
    async refreshTokenManually() {
        if (commonStore.token) {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            if (cognitoUser) {
                const currentSession = await Auth.currentSession();
                cognitoUser.refreshSession(
                    currentSession.refreshToken,
                    (err, session) => { }
                );
                var authToken = cognitoUser.signInUserSession.accessToken.jwtToken;
                var refreshToken = cognitoUser.signInUserSession.refreshToken.token;
                userStore.saveUser(cognitoUser);
                commonStore.setToken(authToken, refreshToken);
            }
        }
    }
    
    @action
    async setProPlan(userActive, type){
        userActive.type = type
        return agent.Auth.setProPlan(userActive).then((response) => {
            if (response) {
                return userStore.getActiveUser().then(() => {
                    return true;
                });
            }
            return false;
        })
        
    }

    @action
    async setFreeNotFree(userActive, type){
        userActive.type = type
        return agent.Auth.setUserFreeOrNotFree(userActive).then((response) => {
            if (response) {
                return userStore.getActiveUser().then(() => {
                    return true;
                });
            }
            return false;
        })
        
    }
    @action
    async resendVerificationCode(userName) {
        try {
            var body = { email: userName };
            return agent.Auth.resendLink(body).then(response => {
                this.reset();
                return response;
            })
                .catch(
                    action(err => {
                        this.errors = err.response && err.response.body && err.response.body.errors;
                        throw err;
                    })
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                    })
                );
            // await Auth.resendSignUp(userName);
        } catch (err) {
            console.log('failed to resend', err)
        }
    }

    @action
    changeUserEmail(oldUserId, newEmail) {
        return agent.Auth.changeUserEmail({ oldUserId, newEmail }).then((response) => {
            if (response) {
                return userStore.getActiveUser().then(() => {
                    return true;
                });
            }
            return false;
        })
    }

    @action
    changePersonalEmail(newEmail) {
        var currentUser = userStore.currentUser;
        return Auth.signIn(currentUser.email, this.values.password).then(async user => {
            return agent.Auth.changeUserEmail({ oldUserId: currentUser.sub, newEmail }).then((response) => {
                if (response) {
                    return true;
                }
                return false;
            }).catch(
                action(error => {
                    this.errors = { general: error.message };
                    throw error;
                })
            );
        }).catch(
            action(error => {
                this.errors = { general: error.message };
                throw error;
            })
        );

    }
}

export default new AuthStore();
